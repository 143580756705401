import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import PortableText from '../components/portableText';
import CategoryTypeRepeater from '../components/CategoryTypeRepeater/category-type-repeater';
import deepmerge from 'deepmerge';
import SEO from '../components/seo';
import GraphQLErrorList from "../components/graphql-error-list";
import scrollTo from 'gatsby-plugin-smoothscroll';

export const query = graphql`
query DecorPageQuery {
  page: sanityDecorPage {
    mainCategory
    title
    _rawDecorTypes
    decorTypes {
      mainImageAlt
      mainImage {
        asset {
          fluid(maxWidth: 1920) {
            ...GatsbySanityImageFluid
          }
        }
      }
      subPageLink{
        slug{
					current
        }
      }
    }
    _rawMainContent
    mainImageAlt
    mainImage {
      asset {
        fluid(maxWidth: 1920) {
          ...GatsbySanityImageFluid
        }
      }
    }
    seoFields {
      title
      description
    }
  }
}
`;

const DecorPage = (props) => {
  const { data, errors } = props;
  const bannerImgFluid = data.page.mainImage.asset.fluid;
  const {seoFields} = data.page;
  const {mainImageAlt} = data.page || '';

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    );
  }


  // this is a bit hacky, probably some better way to set this up in the schema instead
  const rawTypes = data.page._rawDecorTypes;
  const tidyTypes = data.page.decorTypes;
  const mergedTypes = []
  rawTypes.forEach((e, i) => {
    mergedTypes.push(deepmerge(rawTypes[i], tidyTypes[i]))
  });



  return (
    <Layout bannerAlt={mainImageAlt} showBanner={true} bannerImgFluid={bannerImgFluid} bannerTitle={data.page.title}>
      <SEO
        title={seoFields.title}
        description={seoFields.description}
        bodyAttr={{
          class: "",
        }}
      />
      <main  >
        <div className="py-16 md:py-24">
          {/* <h1 className="text-white mb-10 text-3xl">{data.page.title}</h1> */}
          {data.page._rawMainContent &&
            <div className="container mx-auto text-white">
              <PortableText blocks={data.page._rawMainContent} />
              <a className="cta" href="#contact" onClick={() => scrollTo('#contactForm')}>Get a quote</a>
            </div>
          }
        </div>
        <CategoryTypeRepeater categoryTypes={mergedTypes} mainCategory={data.page.mainCategory}/>

      </main>
    </Layout>
  )
};

export default DecorPage;
